import React from 'react'
import Model from './Model'
import Eka_White_Logo from './eka_white_logo.png'
import Login_Page from './login_page.png'
import Loader from "react-loader-spinner";
import Query from "querystring";
import {connect} from "react-redux";
import {attemptlogin} from "../../Redux/Actions/LoginController";
import FloatInput from '../../Component/Common/Views/FloatInput';
import {post} from '../../Model/Network/Config/Axios';
import {handleError, showToast} from '../../Component/Common/Toast';


class index extends React.Component {

	constructor(props) {
		super(props);
		this.Model = new Model();
	}

	componentWillUnmount() {
		this.Model.destroy()
	}

	state = {
		error: undefined,
		isVendor: false,
		success: false
	}

	componentDidMount() {
		let query = Query.parse(this.props.location.search.replace("?", ""));
		let token = query.token;
		let isVendor = query.next
		if (token) {
			this.props.login(token, error => {
				this.setState({
					error,
					isVendor
				})
			})
		} else {
			this.setState({
				error: "No token found in path"
			})
		}
	}

	_onClick = () => {
		let query = Query.parse(this.props.location.search.replace("?", ""));
		let email = this.email.getValue();
		if (email) {
			post(`/auth`, {
				username: email,
				next: query.next
			}, (e, r) => {
				if (e) {
					handleError(e)
				} else {
					this.setState({
						...this.state,
						success: true
					})
				}
			})
		} else {
			showToast({
				type: 'error',
				message: "Please enter a valid email address."
			})
		}

	}


	render() {
		if (this.props.auth.authenticated) {
			let query = Query.parse(this.props.location.search.replace("?", ""));
			let next = query.next;
			if (
				next
			) {
				this.props.history.push(next)
			} else {
				this.props.history.push("/app/h")
			}
		}
		return (
			<div className={`dark flex`} style={{
				height: '100vh',
				width: '100%',
				backgroundColor: '#242731',
				position: 'relative'
			}}>
				<div style={{
					margin: 50,
					position: 'absolute'
				}}>
					<img src={Eka_White_Logo} alt="" style={{
						width: 180,
						height: 48,
						opacity: 0.9
					}} />
				</div>
				<div style={{
					width: '50%',
					height: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<div style={{
						width: "82%",
						paddingLeft: '18%'
					}}>
						<div style={{
							marginBottom: 70,
							color: 'white'
						}}>
							<p style={{
								fontSize: 51,
								marginBottom: 10,
								fontWeight: 700,
								opacity: 0.9,
								letterSpacing: .2,
								textTransform: 'uppercase'
							}}>Welcome to Dice</p>
							<p style={{
								fontSize: 19,
								opacity: 0.7
							}}>AI driven employee spending and travel with unparallel experience, control, speed and
								savings upto 25%</p>
							<div style={{ marginTop: 50, marginBottom: 50, display: 'flex', alignItems: 'center', width: '100%' }}>
								{
									this.state.success ?
										<p style={{
											color: "#6367FA",
											marginTop: 50, marginBottom: 50,
											fontWeight: 600
										}}>
											An email has been generated regarding your login query and has been sent to your email address.
									</p> :
										this.state.isVendor ?
											<div style={{
												flex: 1
											}}>

												<FloatInput
													style={{
														width: '100%'
													}}
													handleRef={ref => this.email = ref}
													title="Enter Email"
													placeholder="Enter the email"
												/>
												<button onClick={this._onClick} className="btn btn_black btn_wide" style={{
													marginTop: 50
												}}>
													SEND LINK
											</button>
												<p style={{
													fontWeight: 600,
													color: '#ff2c34',
													marginTop: 10
												}}>
													{this.state.error}. Please send the link again
												</p>
											</div> :
											this.state.error ?
												<p style={{
													fontWeight: 600,
													color: '#ff2c34',
												}}>
													{this.state.error}
												</p> :
												<Loader
													type="Oval"
													color="#6367FA"
													height={50}
													width={50}
												/>
								}
							</div>
						</div>

					</div>
				</div>
				<div style={{
					height: '100vh',
					textAlign: 'right'
				}}>
					<img src={Login_Page} style={{
						height: '100vh',
					}} alt="" />
				</div>
			</div>
		)
	}

}

export default connect(state => {
	return {
		auth: state.auth
	}
}, dispatch => {
	return {
		login: (token, cb) => {
			dispatch(attemptlogin(token, cb))
		}
	}
})(index);
