import {get, set} from "../../Utils/Crypto";
import Moment from 'moment';

export const AuthActions = {
    Login: "Auth_Login",
    Logout: "Auth_Logout",
    Config: "Auth_Config",
    Theme: 'Theme_Change'

}

let emptyState = {
    authenticated: false,
    lastlogin: undefined,
    config: {},
    theme: 'LIGHT'
}

const initialState = () => {
    const loggedIn = get('loggedin');
    const config = get("config") ? get("config") : {};
    let theme = window.localStorage.getItem('SCANNERS_THEME')
    if(!theme){
        theme = "LIGHT";
    }
    if (loggedIn && loggedIn === "yes") {
        return {
            ...emptyState,
            authenticated: true,
            lastlogin: Moment(get('lastlogin')),
            config,
            theme
        }
    } else {
        return {
            ...emptyState
        };
    }
}

export const auth = (state = initialState(), action) => {
    let data = action.data;
    switch (action.type) {
        case AuthActions.Logout:
            return initialState();
        case AuthActions.Login:
            return {
                ...initialState(),
                authenticated: true,
                lastlogin: new Date()
            }
        case AuthActions.Config:
            set("config", data);
            return {
                ...state, config: data
            }
        case AuthActions.Theme:
            window.localStorage.setItem('SCANNERS_THEME',data)
            return {
                ...state,
                theme: data
            }
        default:
            return state;
    }
}
