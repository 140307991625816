export const Actions = {
    Plugins: "PLUGINS",
}

let emptyState = {
    customApp: false,
    customSupport: false,
    customSurvey: false,
    invoiceOcr: false,
    mileageTracking: false,
    ocr: false,
    procurement: false,
    selfBooking: false,
    singleLine: false,
    loading:true
}

const initialState = () => {
   return {
       ...emptyState
   }
}

export const plugins = (state = initialState(), action) => {
    let data = action.data;
    switch (action.type) {
        case Actions.Plugins: {
            return  {
                ...state,
                ...data
            }
        }
        default:
            return state;
    }
}
