import {AuthActions} from "../Reducer/Auth";
import Moment from 'moment'
import * as Crypto from "../../Utils/Crypto";
import {post} from "../../Model/Network/Config/Axios";
import {showToast,handleError} from "../../Component/Common/Toast/index.js"; 

export const attemptlogin = (token,cb) => {
	return function (dispatch) {
		post('/auth/token', {token}, (error, data) => {
			if (data) {
				Crypto.set('token', data.access_token);
				Crypto.set('refresh_token', data.refresh_token);
				Crypto.set("loggedin", "yes");
				dispatch({
					type: AuthActions.Login,
					data: {
						name: data.name,
						login: Moment()
					}
				})
			} else {
				if (error.data) {
					cb(error.data.error)
				} else {
					cb(error.message)
				}
			}
		})
	}
}



export const attemptlogin1 = (email,password) => {
	return function (dispatch) {
		post('loginAuth/login', {email,password}, (error, data) => {
			if (data) {
				Crypto.set('token', data.access_token);
				Crypto.set('refresh_token', data.refresh_token);
				Crypto.set("loggedin", "yes");
				dispatch({
					type: AuthActions.Login,
					data: {
						name: data.name,
						login: Moment()
					}
				})
			} else {
				if (error.data) {
					showToast(error.data)
				} else {
					handleError(error)
				}
			}
		})
	}
}


