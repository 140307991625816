import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Login from './Login'
import Token from './Login/token'
import ResetPassword from './Login/reset'
import {asyncComponent} from '../Utils/Async';
import './index.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-medium-image-zoom/dist/styles.css'
import {ToastContainer} from "../Component/Common/Toast/ToastContainer";
import {Toaster} from 'react-hot-toast'
import ReactTooltip from "react-tooltip";

export default function Index(){

		return (
			<div>
				<ToastContainer />
				<Toaster/>
				<BrowserRouter>
					<Switch>
						<Route path="/" component={Login} exact={true} />
						<Route path="/login" component={Token} exact={true} />
						<Route path="/reset" component={ResetPassword} exact={true} />
						<Route path="/app" component={asyncComponent(() => import("./AuthenticatedController"))} />
					</Switch>
					<ReactTooltip backgroundColor="#111111" borderColor="#111111" type="dark" offset={{ right: 0 }}
								  multiline={false} effect="solid" place="right" />
				</BrowserRouter>
			</div>
		)

}
