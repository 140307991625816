import React, { useEffect, useRef, useState} from 'react'
import {handleError, showToast} from "../../Component/Common/Toast";
import {post} from "../../Model/Network/Config/Axios";
import {connect} from "react-redux";
import Query from "querystring";
// import Lock from './lock.png'
import Back from './abstract.jpg'
import Tick from './tick.png'
import bs from '../../Assets/bootstrap.module.css'
import { attemptlogin1 } from '../../Redux/Actions/LoginController';

function Login(props) {

	const [getMailID, setMailID] = useState('');
	const [getVerify, setVerify] = useState(false);
	const [tab,setTab] = useState(1);
	const [loading,setLoading] = useState(false);
	const [forgotPassword,setForgotPassword] = useState(false);
	const [reset,setReset] = useState(undefined);
	let Email = useRef(null), PASSWORD = useRef(), CNFPASSWORD = useRef(), OTP = useRef();

	useEffect( async ()=>{
		navigator.serviceWorker.getRegistrations().then((registrations) => {
			registrations.forEach((registration) => {
				registration.unregister();
			});
		});
		caches.keys().then((keyList) => {
			console.log(keyList);
			return Promise.all(
			  keyList.map((key) => {
				return caches.delete(key);
			  })
			);
		  });
	},[])

	const LoginHandler = () =>{
		let email = Email.current.value;
		let password = PASSWORD.current.value;
		if(!password){
			showToast({
				type: 'error',
				message: "Please enter password"
			})
			return;
		}
		if(!email){
			showToast({
				type: 'error',
				message: "Please enter email"
			})
			return;
		}

		props.login(email,password)
	}

	const sendOTP = () => {
		let email = Email.current.value;
		if(!email){
			showToast({
				type: 'error',
				message: "Please enter email"
			})
			return;
		}
		setLoading(true);
		post(`loginAuth/sendEmail`,{email},(e,r)=>{
			if(r){
				showToast({
					type: 'success',
					message: "OTP send successfully."
				})
				setReset(email);
				setTab(3);
			}else{
				handleError(e);
			}
			setLoading(false);
		})
	}

	const ResetHandler = () => {
		let otp = OTP.current.value;
		let password = PASSWORD.current.value;
		let confirmPassword = CNFPASSWORD.current.value;
		let email = reset;
		if(!email){
			showToast({
				type: 'error',
				message: "Please enter email"
			})
			return;
		}
		if(!password){
			showToast({
				type: 'error',
				message: "Please enter password"
			})
			return;
		}
		if(!confirmPassword){
			showToast({
				type: 'error',
				message: "Please enter confirm Password"
			})
			return;
		}
		if(!otp){
			showToast({
				type: 'error',
				message: "Please enter otp"
			})
			return;
		}
		if(password!==confirmPassword){
			showToast({
				type: 'error',
				message: "Password and confirm password must be same."
			})
			return;
		}
		setLoading(true);
		post(`loginAuth/resetPassword`,{email,password,confirmPassword,otp},(e,r)=>{
			if(r){
				showToast({
					type: 'success',
					message: "password reset successfully"
				})
				setReset(undefined);
			}else{
				handleError(e);
			}
			setLoading(false);
		})
	}

	const _onClick = e => {
		let email = '';
		if(getMailID===''){
			email = Email.current.value;
		}else{
			email = getMailID;
		}
		setLoading(true);
		if (email) {
			post(`/auth`, {
				username: email
			}, (e, r) => {
				if (e) {
					handleError(e)
				} else {
					setMailID(email);
					setVerify(true);

					showToast({
						type: 'success',
						message: "Please check your Inbox"
					})
				}
				setLoading(false);
			})
		} else {
			setLoading(false);
			showToast({
				type: 'error',
				message: "Please enter an Email address."
			})
			
		}
	}


		if (props.auth.authenticated) {
			let query = Query.parse(props.location.search.replace("?", ""));
			let next = query.next;
			if(
				next
			){
				props.history.push(next)
			}else {
				props.history.push("/app/h")
			}
		}

	return (
		<>
			<div style={{
			background: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)) center / cover no-repeat, url('${Back}') center / cover no-repeat`,
			height: '100vh',
			fontSize: '16px'
			}}>
				<div className={`${bs.container} ${bs['h-100']}`} style={{maxWidth: '1500px'}}>
					<div className={`${bs.row} ${bs['justify-content-center']} ${bs['align-items-center']} ${bs['h-100']}`} style={{color: '#333'}}>
						<div data-aos="fade-right" className={`${bs['col-md-6']} ${bs['d-none']} ${bs['d-sm-none']} ${bs['d-md-flex']} ${bs['text-white']}`}>
							<div style={{maxWidth: '400px', margin: 'auto'}}>
								<h6 className={bs['fw-bold']}>Quick Tips</h6>
								<h1 className={`${bs['display-3']} ${bs['fw-bold']}`}>Dice Vendor Portal.</h1>
								<p>Dice Vendor portal makes your client onboarding process fast and smooth, with everything
									availalbe at one place.</p>
							</div>
						</div>
						<div className={bs['col-md-6']}>
						{getVerify?
						<div className={`${bs['p-5']} ${bs['text-center']} ${bs['m-auto']}`}  data-aos="fade-up" style={{borderRadius: '1rem',boxShadow: '0 0.6rem 1.5rem rgba(0,0,0,0.3)',maxWidth: '500px',backdropFilter: 'blur(10px)',backgroundColor: 'rgba(255,255,255,0.9)'}}><img className="img-fluid mb-4 m-auto" src={Tick} style={{maxWidth: '80px'}} alt=""/>
						<h1 className={`${bs['display-6']} ${bs['fw-bold']} ${bs['mt-3']}`}>Sent</h1>
						<p>We have sent a link to your email, use email link to login into your account.</p>
						<p className="mt-3"><a onClick={_onClick} className={`${bs['text-primary']} ${bs.links}`} href="#1">Resend Email&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right-circle-fill">
									<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"></path>
						</svg>&nbsp;</a></p>
						</div>:
							<div data-aos="fade-left" className={`${bs['p-5']} ${bs['text-center']}`} style={{
								backdropFilter: 'blur(10px)',
								margin: 'auto',
								backgroundColor: 'rgba(255,255,255)',
								borderRadius: '1rem',
								maxWidth: '400px',
								boxShadow: '0 1rem 2rem rgba(0,0,0,0.3)',
								minWidth: '380px'
							}}>
								{/* <img className={`${bs['img-fluid']} ${bs['mb-4']} ${bs['m-auto']}`} src={Lock} style={{maxWidth: '80px'}} alt=""/>
								<h1 className={`${bs['display-6']} ${bs['fw-bold']}`}>Log in</h1>
								<p>Welcome, enter your Email</p> */}
								<div style={{display:'flex',background:'rgb(242, 244, 246)',width:'240px',height:'40px',padding:'2px',border:'1px solid rgb(242, 244, 246)',borderRadius:'8px',margin:'0 0 32px 0'}}>
									<div onClick={()=>{
										if(loading) return;
										setTab(1);
										setForgotPassword(false);
										setReset(undefined);
									}} style={{width:'120px',height:'34px',cursor:'pointer',textAlign:'center',borderRadius:'4px',...(tab===1)?{background:'rgb(255, 255, 255)',border:'1px solid rgb(255, 255, 255)',boxShadow:'rgba(16, 24, 40, 0.25) 0px 1px 2px'}:{}}}>
										<p style={{fontSize:'14px',fontWeight:600,padding:'7px 0'}}>Email</p>
									</div>
									<div onClick={()=>{
										if(loading) return;
										setTab(2);
										setForgotPassword(false);
										setReset(undefined);
									}} style={{width:'120px',height:'34px',cursor:'pointer',textAlign:'center',borderRadius:'4px',...(tab===2 || tab===3)?{background:'rgb(255, 255, 255)',border:'1px solid rgb(255, 255, 255)',boxShadow:'rgba(16, 24, 40, 0.25) 0px 1px 2px'}:{}}}>
										<p style={{fontSize:'14px',fontWeight:600,padding:'7px 0'}}>Password</p>
									</div>
								</div>
								{tab === 1 && 
									<form onSubmit={(e) => {
										e.preventDefault();
										_onClick();
									}}>
										<div className={`${bs['mt-5']} ${bs['mb-4']}`}>
											<input ref={Email} type="text" className={`${bs['form-control']} ${bs.mb-2}`}
												placeholder="Your Email Address"/>
										</div>
										<button type='submit' className={`${bs.btn} ${bs['btn-primary']} ${bs['w-100']}`} x="button">Send Link</button>
									</form>
								}
								{tab === 2 &&
									<>
										<form onSubmit={(e) => {
											e.preventDefault();
											if(forgotPassword) sendOTP();
											else LoginHandler();
										}}>
											<div className={`${bs['mt-5']} ${bs['mb-4']}`}>
												<input ref={Email} type="text" className={`${bs['form-control']} ${bs.mb - 2}`}
													placeholder="Email Address" />
												{!forgotPassword && <div style={{display:'flex',position:'relative'}}>
													<input ref={PASSWORD} style={{marginTop:'15px'}} type="password" className={`${bs['form-control']} ${bs.mb - 2}`}
													placeholder="Password" />
												</div>}
											</div>
											<button disabled={loading} type='submit' className={`${bs.btn} ${bs['btn-primary']} ${bs['w-100']}`} x="button">{forgotPassword?'Send OTP':'Login'}</button>
										</form>
										{!forgotPassword&&<p onClick={()=>setForgotPassword(true)} className={bs['mt-3']} style={{ fontSize: '14px', cursor:'pointer' }}>
											<p className={`${bs['text-primary']} ${bs.links}`}>Forgot Password</p>
										</p>}
									</>
								}
								{
									tab === 3 &&
									<>

										{reset?<form onSubmit={(e) => {
											e.preventDefault();
											ResetHandler();
										}}>
											<div className={`${bs['mt-5']} ${bs['mb-4']}`}>
												<input ref={PASSWORD} type="password" className={`${bs['form-control']} ${bs.mb - 2}`}
													placeholder="Password" />
												<input ref={CNFPASSWORD} style={{marginTop:'15px'}} type="password" className={`${bs['form-control']} ${bs.mb - 2}`}
													placeholder="Confirm Password" />
												<input ref={OTP} style={{marginTop:'15px'}} type="text" className={`${bs['form-control']} ${bs.mb - 2}`}
													placeholder="OTP" />
											</div>
											<button disabled={loading} type='submit' className={`${bs.btn} ${bs['btn-primary']} ${bs['w-100']}`} x="button">{'Reset'}</button>
										</form>:
										<div>
											<p>Password reset successfully</p>
											<p className="mt-3"><a onClick={()=>{
												if(loading) return;
												setTab(2);
												setForgotPassword(false);
												setReset(undefined);
											}} className={`${bs['text-primary']} ${bs.links}`} href="#1">Login&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right-circle-fill">
														<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"></path>
											</svg>&nbsp;</a></p>
										</div>
										}

									</>
								}
								
							</div>
						}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}


export default connect(state => {
	return {
		auth: state.auth
	}
}, dispatch => {
	return {
		login: (email, password) => {
			dispatch(attemptlogin1(email, password))
		}
	}
})(Login);
