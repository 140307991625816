import React from 'react';
import {Provider} from 'react-redux';
import Store from './Redux';
import './Assets/styles.css'
import './Assets/app.css'
import './Assets/shine.css';
import './Assets/theme.css';
import Container from './Pages'
import AlertTemplate from "react-alert-template-basic";
import {positions, Provider as ToastProvider} from "react-alert";
//import('./Assets/theme.css')

const ReduxStore = Store();

export default function Index() {
    return (
        <ToastProvider template={AlertTemplate} {...{
            timeout: 5000,
            position: positions.BOTTOM_CENTER
        }}>
            <Provider store={ReduxStore}>
                <Container/>
            </Provider>
        </ToastProvider>
    )
}

