import React from 'react'
import Model from './Model'
import Eka_White_Logo from './eka_white_logo.png'
import Login_Page from './login_page.png'
import Loader from "react-loader-spinner";
import Query from "querystring";
import {connect} from "react-redux";
import {attemptlogin} from "../../Redux/Actions/LoginController";


class index extends React.Component {

	constructor(props) {
		super(props);
		this.Model = new Model();
	}

	componentWillUnmount() {
		this.Model.destroy()
	}

	state = { error: undefined }

	componentDidMount() {
		let query = Query.parse(this.props.location.search.replace("?", ""));
		let token = query.token;
		if (token) {
			this.props.login(token, error => {
				this.setState({
					error
				})
			})
		} else {
			this.setState({
				error: "No token found in path"
			})
		}
	}


	render() {

		if (this.props.auth.authenticated) {
			let query = Query.parse(this.props.location.search.replace("?", ""));
			let next = query.next;
			if(
				next
			){
				this.props.history.push(next)
			}else {
				this.props.history.push("/app/p/home")
			}
		}
		return (
			<div className={`dark flex`} style={{
				height: '100vh',
				width: '100%',
				backgroundColor: '#242731',
				position: 'relative'
			}}>
				<div style={{
					margin: 50,
					position: 'absolute'
				}}>
					<img src={Eka_White_Logo} alt="" style={{
						width: 180,
						height: 48,
						opacity: 0.9
					}} />
				</div>
				<div style={{
					width: '50%',
					height: '100vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
					<div style={{
						width: "82%",
						paddingLeft: '18%'
					}}>
						<div style={{
							marginBottom: 70,
							color: 'white'
						}}>
							<p style={{
								fontSize: 51,
								marginBottom: 10,
								fontWeight: 700,
								opacity: 0.9,
								letterSpacing: .2,
								textTransform: 'uppercase'
							}}>Welcome to Eka</p>
							<p style={{
								fontSize: 19,
								opacity: 0.7
							}}>AI driven employee spending and travel with unparallel experience, control, speed and
								savings upto 25%</p>
							<div style={{ marginTop: 50, marginBottom: 50, display: 'flex', alignItems: 'center' }}>
								{
									this.state.error ?
										<p style={{
											fontWeight: 600,
											color: '#ff2c34',
										}}>
											{this.state.error}
										</p> :
										<Loader
											type="Oval"
											color="#6367FA"
											height={50}
											width={50}
										/>
								}
							</div>
						</div>

					</div>
				</div>
				<div style={{
					height: '100vh',
					textAlign: 'right'
				}}>
					<img src={Login_Page} style={{
						height: '100vh',
					}} alt="" />
				</div>
			</div>
		)
	}

}

export default connect(state => {
	return {
		auth: state.auth
	}
}, dispatch => {
	return {
		login: (token, cb) => {
			dispatch(attemptlogin(token, cb))
		}
	}
})(index);
